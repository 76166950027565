<template>
  <div class="terms-modal">
    <div class="terms-header-container">
      <img
        class="charity-water-logo"
        alt="Charity:water logo"
        src="../../assets/logoCharityWater.webp"
      />
      <img
        class="terms-close"
        alt="x-icon"
        src="../../assets/x-icon.png"
        @click="closeModal"
      />
    </div>
    <hr class="terms-top-divider" />
    <div class="terms-content-container">
      <h1 class="terms-title">Terms of Service For Access to Sensor Data</h1>
      <div class="terms-text">
        <p class="paragraph-small">Last updated (April 26, 2023)</p>
        <p class="paragraph-small">
          Thank you for participating in charity: water's sensor data project!
          We are excited to work with you to use technology to improve access to
          clean, safe water. It's important that you read these Terms of Service
          carefully. In order to have access to the Service you must agree to
          all of the terms by clicking Accept.
        </p>
        <h3>About us</h3>
        <p class="paragraph-small">
          charity: water is a non-profit organization based in New York City
          working to bring clean and safe drinking water to people in developing
          countries.
        </p>
        <h3>Terms</h3>
        <p class="paragraph-small">
          “We” or “us” refers to charity: water and any individual or
          organization working with charity: water. “You” refers to both you as
          an individual and the organization you represent. “Service” refers to
          the data received from any water sensor provided or manufactured by
          charity: water, any compilation of that data, and any access to that
          data in whatever form including data in the aggregate or data that has
          been otherwise manipulated.
        </p>
        <h3>What Our Service Does</h3>
        <p class="paragraph-small">
          The charity: water Service provides monitoring data on the performance
          of water systems so that repairs can be made quickly and efficiently,
          and down time can be minimized. The Service uses IoT sensors and a
          cloud computing platform that collects data from all the sensors,
          which allows us to analyze and provide useful information back to you.
          You can access data by subscribing to receive emailed reports. In
          addition to the data captured by sensors, you may contribute
          supplementary information on the water systems being monitored--such
          as the name of the community or the phone number of the
          caretaker--which may make the reports more useful. By accepting these
          Terms, you confirm that you understand that the Service is an
          innovative, experimental approach that is still being tested so that
          charity: water cannot and does not guarantee the performance of the
          hardware and software. Technical issues with the sensors, or data
          quality or data transmission issues, may prevent us from providing the
          Service. In the event that the Service is interrupted, faulty or
          terminated, charity: water cannot be held liable for any consequences
          of nonperformance.
        </p>
        <h3>Eligibility</h3>
        <p class="paragraph-small">
          In order to use the Service, you must have been granted access by
          charity: water or one of our Partner organizations, you must be at
          least 18 years of age, and you must provide us with personal
          information that is necessary to provide you with our Service,
          including to your name and email address, which we will use to
          facilitate access to the Data.
        </p>
        <h3>Intellectual Property Rights</h3>
        <p class="paragraph-small">
          charity:water is the owner of all of the intellectual property related
          to the hardware and the software including the Service. All data
          collected by the Service is owned by charity: water. Furthermore you
          agree that charity: water may use and publish any information you
          provide in connection with the Service (such as identifying
          information about each sensor) for any purpose. charity: water may
          share findings and data derived from the Service with third parties
          for any purpose, but will only make external mention of your
          organization with the organization's prior consent. You agree not to
          upload, download, display, perform, transmit, or otherwise distribute
          any information or content in violation of any third party's
          copyrights, trademarks, or other intellectual property or proprietary
          rights. You shall be solely responsible for any violations of any
          relevant laws and for any infringements caused by any content you
          provide or transmit, or that is provided or transmitted using your
          account. The burden of proving that any content does not violate any
          laws or third party rights rests solely with you.
        </p>
        <h3>Limited License</h3>
        <p class="paragraph-small">
          Because of your consent to these Terms and a participation in the
          sensor program, charity: water agrees to grant you a limited,
          revocable, non-exclusive worldwide license to access the Service and
          use data for your operational, research, evaluation or other purposes
          that increase access to clean water. You agree to not use the Service
          for illegal purposes. You agree not to reverse engineer, disassemble,
          decompile, alter, duplicate, translate, make copies or create
          derivative works from the device or firmware, or otherwise attempt to
          derive the source code of the firmware. Any information discovered as
          a result of these activities must be promptly disclosed to charity:
          water and is deemed to be the confidential and proprietary information
          of charity: water. charity: water may revoke this license to you at
          any time. charity: water shall not be liable to you for any loss in
          the event of such a revocation.
        </p>
        <h3>Representations & Warranties</h3>
        <p class="paragraph-small">
          This is a unique, experimental Service that is still being tested and
          refined. We are not sure if (or how well) it will perform or how long
          it will continue to provide information. We make no representations or
          warranties as to the reliability of our Service or fitness for any
          particular purpose including purposes known to us.
        </p>
        <p class="paragraph-small">
          By agreeing to these Terms of Service, you hereby represent and
          warrant that you have the requisite legal power and authority to
          execute this Agreement on behalf of your organization and bind your
          organization to the obligations herein.
        </p>
        <h3>Release and Indemnity</h3>
        <p class="paragraph-small">
          By accessing the Service, you agree to unconditionally release us from
          any liability in relation to or arising from this Agreement or the
          Service, for reasons including, but not limited to, failure of the
          Service, negligence, or any other reason. To the extent that
          applicable law restricts this release of liability, you agree that we
          are only liable to you for the minimum amount of damages that the law
          restricts our liability to, if such a minimum exists, which in no case
          shall be more than the amount of money you have paid for access to the
          service.
        </p>
        <p class="paragraph-small">
          You agree that we are not responsible in any way for damages caused by
          third parties who may use our services, including but not limited to
          people who commit intellectual property infringement, defamation,
          tortious interference with economic relations, or any other actionable
          conduct towards you.
        </p>
        <p class="paragraph-small">
          You agree to be liable to us capped at the value of the sensors
          delivered under this Agreement against, and in respect to, any and all
          losses, expenses, costs, obligations, liabilities and damages,
          including interest, penalties and reasonable attorney's fees and
          expenses, that we may incur as a result of any negligent or willful
          acts or omissions of you or any of your agents or employees, including
          but not limited to failure to comply with any applicable laws. These
          provisions will survive the termination of this Agreement. Without
          limitation of the foregoing, you may not settle, compromise or in any
          other manner dispose of any claim, demand, or action without our
          express written consent.
        </p>
        <h3>Choice of Law</h3>
        <p class="paragraph-small">
          This Agreement shall be governed by the laws of the state of New York,
          United States of America, without regard to conflict-of-laws
          principles. The offer and acceptance of this Agreement are deemed to
          have occurred in the State of New York, United States of America.
        </p>
        <h3>Forum of Dispute</h3>
        <p class="paragraph-small">
          The parties agree that any claim or dispute arising from or related to
          this Agreement shall be settled by mediation and, if necessary,
          legally binding arbitration in accordance with the Rules of the
          American Arbitration Association in New York City, conducted by a
          single arbitrator appointed by the American Arbitration Association.
          Judgment upon an arbitration decision may be entered in any court
          otherwise having jurisdiction. The parties understand that these
          methods shall be the sole remedy for any controversy or claim arising
          out of this Agreement and expressly waive their right to file a
          lawsuit in any civil court against one another for such disputes,
          except to enforce an arbitration decision. The venue for any
          enforcement action shall also be New York City, New York, USA
        </p>
        <h3>Force Majeure</h3>
        <p class="paragraph-small">
          You agree that we are not responsible to provide the Service if we are
          prevented as a result of natural disaster (including fire, flood,
          earthquake, storm, hurricane or other natural disaster), pandemic or
          epidemic, war, invasion, act of foreign enemies, hostilities
          (regardless of whether war is declared), civil war, rebellion,
          revolution, insurrection, military or usurped power or confiscation,
          terrorist activities, nationalization, government sanction, blockage,
          embargo, labor dispute, strike, lockout or interruption or failure of
          electricity, telephone service or other methods of communication
          (Force Majeure). If an event of Force Majeure occurs, we intend to
          cooperate with and assist you in all reasonable ways to minimize the
          impact of force majeure on you.
        </p>
        <h3>Severability</h3>
        <p class="paragraph-small">
          In the event that any provision or any part of a provision of the
          Agreement shall be finally determined to be superseded, invalid,
          illegal or otherwise unenforceable pursuant to applicable laws by an
          authority having jurisdiction, such determination shall not impair or
          otherwise affect the validity, legality, or enforceability of the
          remaining provisions or parts of provisions thereof, which shall
          remain in full force and effect as if the unenforceable provision or
          part were deleted.
        </p>
        <h3>Non-Waiver</h3>
        <p class="paragraph-small">
          charity: water reserves all rights afforded to us under this Agreement
          as well as under the provisions of any applicable law. Our
          non-enforcement of any particular provision or provisions of this
          Agreement or any applicable law should not be construed as our waiver
          of the right to enforce that same provision under the same or
          different circumstances at any time in the future.
        </p>
        <h3>Termination & Cancellation</h3>
        <p class="paragraph-small">
          We may terminate your access to our Service at our discretion without
          explanation, though we will strive to provide a timely explanation in
          most cases. Our liability for refunding you, if you have paid anything
          to us, will be limited to the amount you paid for goods or services
          which have not yet been and will not be delivered, except in cases
          where the termination or cancellation was due to your breach of this
          Agreement, in which case you agree that we are not required to provide
          any refund or other compensation whatsoever. We may deny you access to
          all or part of the Service at any time for any reason (including, but
          not limited to, if you engage in any conduct or activities that we
          determine, in our sole discretion, violate these Terms of Service, our
          rights or the rights of any third party ) or no reason at all. Under
          no circumstances, including termination or cancellation of our Service
          to you, will we be liable for any losses related to actions of other
          Users.
        </p>
        <h3>Relationship between Parties and Assignment of Rights</h3>
        <p class="paragraph-small">
          Your organization and charity: water are independent of one another,
          and neither has the authority to bind the other to any third party or
          to act in any way as a representative of the other, except as
          expressly set forth herein. This Agreement does not create, and shall
          not be construed as creating, a partnership or joint venture
          relationship between the parties. You may not assign your rights
          and/or obligations under this Agreement to any other party without our
          prior written consent. We may assign our rights and/or obligations
          under this Agreement to any other party at our discretion.
        </p>
        <h3>Amendments</h3>
        <p class="paragraph-small">
          We may amend this Agreement from time to time. When we amend this
          Agreement, we will update this page accordingly. Your continued use of
          our Service shall constitute your acceptance of any such amendments.
        </p>
        <h3>Miscellaneous</h3>
        <p class="paragraph-small">
          This Agreement constitutes the entire agreement between you and
          charity: water concerning the Service and supersedes all prior oral
          representations or other agreements. Neither the course of conduct
          between the parties nor trade practice shall act to modify any of
          these Terms of Service.
        </p>
        <h3>For More Information</h3>
        <p class="paragraph-small">
          For additional information, our Privacy Policy can be found at:
          https://www.charitywater.org/privacy. If you have any questions,
          concerns, or comments about these Terms of Service, please send us a
          description of your concern via e-mail to sensors@charitywater.org or
          call the main charity: water telephone number at +1-646-688-2323.
        </p>
      </div>
    </div>
    <hr class="terms-bottom-divider" />
    <div class="terms-footer-container">
      <button class="done-button" @click="closeModal">Done</button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.terms-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  width: 780px;
  height: 580px;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .terms-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 1em;
    padding: 1em 1em 0px 1em;
    margin-top: 24px;
    margin-bottom: 32px;
    width: 100%;
    .charity-water-logo {
      width: 330px;
      margin-right: 170px;
      @media screen and (max-width: 767px) {
        margin-right: 10vw;
      }
    }
    .terms-close {
      width: 14px;
      margin-right: 32px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  hr {
    border: none;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #727785a6;
  }
  .terms-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-left: 8%;
    margin-right: 8%;
    overflow-y: scroll;
    .terms-title {
      font-size: 20px;
      font-weight: 700;
      color: #1d2433;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  // https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp
  .terms-content-container::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .terms-content-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .terms-footer-container {
    align-self: flex-end;
    margin-right: 16px;
    margin-bottom: 16px;
    .done-button {
      padding: 12px 16px;
      background-color: #003366;
      margin-top: 16px;
      color: #fff;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>
