<template>
  <div class="change-password-form">
    <div class="login-container">
      <img
        class="charity-water-logo"
        alt="Charity:water logo"
        src="../../assets/charity_water_logo_wText.svg"
      />
      <form class="login-container-form" @submit.prevent="onSubmit">
        <!-- Create Password -->
        <div class="form-field">
          <label class="login-container-form-label">Create Password</label>
          <FieldPassword
            :value="password"
            @passwordChanged="onPasswordChange"
          />
        </div>
        <!-- Confirm Password -->
        <div class="form-field">
          <label class="login-container-form-label">Re-Enter Password</label>
          <FieldPassword
            :value="confirmPassword"
            @passwordChanged="onConfirmPasswordChange"
          />
        </div>
        <p class="login-container-form-helpMessage">
          {{ passwordStrings.requirements }}
        </p>
        <label for="terms" class="terms-checkbox">
          <input
            id="terms"
            type="checkbox"
            class="terms-checkbox-input"
            required
          />
          <span class="terms-checkbox-text">
            I have read and accept the
            <a class="terms-checkbox-link" @click="showModal = true"
              >Terms and Conditions</a
            >
          </span>
        </label>
        <button class="login-container-form-submitButton" type="submit">
          Update Password
        </button>
        <p v-if="passwordMatch === false" class="password-match-error">
          {{ this.errorMessage }}
        </p>
      </form>
    </div>
    <TermsModal v-if="showModal" @close="closeModal" />
  </div>
</template>

<script>
import { loginCognito } from "../../api/cognito";
import TermsModal from "./TermsModal.vue";
import { ToastMessages, Passwords } from "../Shared/strings";
import FieldPassword from "../Forms/FieldPassword.vue";

export default {
  components: {
    TermsModal,
    FieldPassword,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      errorMessage: "",
      passwordMatch: null,
      showModal: false,
    };
  },
  computed: {
    roles() {
      return this.$store.state.roles;
    },
    tempPassword() {
      return this.$store.state.tempPassword;
    },
    passwordStrings() {
      return Passwords;
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    async onSubmit() {
      this.verifyPasswords();
    },
    verifyPasswords() {
      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()`~,.<>?;:+=_-])(?!.*\s).{8,}$/;
      const isPasswordValid = passwordRegex.test(this.password);

      if (this.password !== this.confirmPassword) {
        this.passwordMatch = false;
        this.errorMessage =
          "The password and confirmation password do not match.";
      } else if (!isPasswordValid) {
        this.passwordMatch = false;
        this.errorMessage = this.passwordStrings.invalid;
      } else {
        this.passwordMatch = true;
        this.errorMessage = "";

        const userName = JSON.parse(
          sessionStorage.getItem("sessionUserAttributes"),
        ).email;
        loginCognito(
          userName,
          this.tempPassword,
          this.onAuthSuccess,
          this.onAuthFail,
          this.onChangePassword,
        );
      }
    },
    onAuthSuccess() {},
    onAuthFail() {},
    onChangePassword(userAttributes, cognitoUser) {
      userAttributes = userAttributes || null;
      this.$store.dispatch("startLoading", "passwordReset");
      cognitoUser.completeNewPasswordChallenge(this.password, null, {
        onSuccess: this.onResetComplete,
        onFailure: this.onResetComplete,
        newPasswordRequired: this.onResetComplete,
      });
    },
    async onResetComplete() {
      this.$store.dispatch("clearTempPassword");
      this.$store.dispatch("endLoading", "passwordReset");
      this.$store.dispatch("showToast", {
        message: ToastMessages.user.password.change.success,
        type: "success",
      });
      this.$router.push("/login");
    },
    onPasswordChange(password) {
      this.password = password;
    },
    onConfirmPasswordChange(confirmPassword) {
      this.confirmPassword = confirmPassword;
    },
  },
  mounted() {
    if (
      !sessionStorage.getItem("sessionUserAttributes") ||
      !this.$store.state.tempPassword
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped lang="scss">
.change-password-form {
  max-width: 600px;
  width: 100%;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 600px;
  width: 100%;
  border-radius: 0.5em;
  gap: 24px;
  padding: 60px 160px;

  .terms-checkbox {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    font-size: 12px;
    font-weight: 400;
    color: rgb(92, 92, 92);

    &-input {
      width: 1em;
    }
    &-link {
      color: #2f5982;
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    width: 100%;
    gap: 24px;

    &-helpMessage {
      font-size: 0.75em;
      color: #85878d;
      text-align: center;
      margin-top: 5px;
      margin-bottom: 2px;
    }
  }

  &-forgotpass {
    margin: 1.438em 0 0 0;
    font-size: 0.8em;
    font-weight: 700;
    color: #476c90;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }

  &-error {
    color: red;
    font-size: 0.8em;
  }
}
.password-match-error {
  color: red;
  font-size: 0.75em;
}
</style>
