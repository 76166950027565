<template>
  <div class="changePassword-page">
    <ChangePasswordForm />
    <div class="changePassword-page-help">
      <HelpSign />
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from "../components/Forms/ChangePasswordForm.vue";
import HelpSign from "../components/NavComponents/HelpSign.vue";

export default {
  name: "default-component",
  props: {},
  model: {
    event: "updateModel",
  },
  components: {
    HelpSign,
    ChangePasswordForm,
  },
  created() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.changePassword-page {
  background-color: #003366;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-help {
    position: fixed;
    bottom: 0.25em;
    left: 97%;
  }
}
</style>
